<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-5 mb-lg-20">
        <img :src="siteLogo()" alt="Logo" width="100%" />
      </div>

      <div class="text-center mb-5 mb-lg-20">
        <!-- <h3 class="font-size-h1">Login</h3> -->
        <p class="font-weight-semi-bold mt-7" style="color: white;">
          Reset your password
        </p>
        <!-- <p>Logging you in...</p> -->
      </div>

      <!--begin::Form-->
      <v-form v-if="!wasReset" class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" v-if="errors && errors.length">
          <v-alert border="right" colored-border type="error" elevation="2">
            {{ errors }}
          </v-alert>
        </div>

        <v-container fluid>
          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            dense
            color="#F06835"
            label="Enter your email"
            required
            v-model="$v.form.email.$model"
            :error-messages="emailErrors"
            @input="$v.form.email.$touch()"
            @blur="$v.form.email.$touch()"
          ></v-text-field>

          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            dense
            color="#F06835"
            label="Enter your password"
            :type="showPassword ? 'text' : 'password'"
            @click:append.prevent="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            required
            v-model="$v.form.password.$model"
            :error-messages="passwordErrors"
            @input="$v.form.password.$touch()"
            @blur="$v.form.password.$touch()"
          ></v-text-field>

          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            dense
            color="#F06835"
            label="Confirm your password"
            :type="showPassword ? 'text' : 'password'"
            @click:append.prevent="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :extra-rules="[
              form.password === form.confirmPassword || 'Passwords do not match'
            ]"
            required
            v-model="$v.form.confirmPassword.$model"
            :error-messages="confirmPasswordErrors"
            @input="$v.form.confirmPassword.$touch()"
            @blur="$v.form.confirmPassword.$touch()"
          ></v-text-field>
        </v-container>

        <div class="form-group d-flex flex-column align-items-end">
          <v-btn
            type="submit"
            color="#F06835"
            dark
            class="mb-2 mt-2"
            style="min-width: 188px"
            :loading="isSubmitting"
          >
            Reset Password
          </v-btn>
        </div>
      </v-form>

      <!--end::Form-->
      <div v-else class="h3">
        <p class="font-weight-semi-bold mt-7" style="color: white;">
          Your password has been reset.
        </p>
        <div class="form-group d-flex flex-column align-items-end">
          <v-btn
            color="#F06835"
            dark
            class="mb-2 mt-2"
            style="min-width: 188px"
            @click="goToLogin"
          >
            To Login Page
          </v-btn>
        </div>
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { LOGIN, LOGOUT, REFRESH } from "@/core/store/auth.module";
import { mapGetters } from "vuex";
import objectPath from "object-path";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import Mgr from "@/core/services/security.service";
import { RESET_PASSWORD } from "@/core/store/forgotPassword.module";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      mgr: new Mgr(),
      // Remove this dummy login info
      // username: "",
      // password: "",
      isSubmitting: false,
      showPassword: false,
      form: {
        email: null,
        password: null,
        confirmPassword: null
      },
      code: null,
      wasReset: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        minLength: minLength(3)
      }
    }
  },
  async created() {
    // window.confirm("login:load");
  },
  async mounted() {
    this.code = this.$route.query.code;
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapState({
      errors: state => state.auth.errors,
      isAuthenticated: state => state.auth.isAuthenticated
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Must be valid e-mail");
      !this.$v.form.email.required && errors.push("Email is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.minLength &&
        errors.push("Must be at least 8 characters long");
      !this.$v.form.password.required && errors.push("Password is required");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.minLength &&
        errors.push("Must be at least 8 characters long");
      !this.$v.form.confirmPassword.required &&
        errors.push("Confirm Password is required");
      !this.$v.form.password.$model !== !this.$v.form.confirmPassword.$model;
      errors.push("Passwords don't match");
      return errors;
    }
  },
  methods: {
    async goToLogin() {
      await this.$router.push({ name: "login" });
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
        confirmPassword: false
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.errorMessage = "";

      const { success, error } = await this.$store.dispatch(RESET_PASSWORD, {
        email: this.form.email,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        code: this.code
      });

      if (success) {
        this.wasReset = true;
      } else {
        this.$toast.error(error.message ?? "Something went wrong.");
      }

      if (this.errors) {
        if (Array.isArray(this.errors))
          this.errorMessage = this.errors
            .map(({ message }) => message)
            .join("; ");
        else this.errorMessage = this.errors;
      }

      // submitButton.classList.remove(
      //   "spinner",
      //   "spinner-light",
      //   "spinner-right"
      // );
    }
  }
};
</script>

<style lang="scss" scoped>
.password-reset {
  font-weight: 500;
  color: black;
  &:hover {
    color: #ab2f75;
  }
}

p,
label {
  color: white;
}

input[type="text"] {
  background-color: white;
}
</style>
